.my-story__toc {
    list-style-type: none;
    padding: 0;
    margin-bottom: 2em;
}

.my-story__toc li {
    margin: 0.5em 0;
}

.my-story__toc a {
    color: var(--clr-fg);
    text-decoration: none;
    font-size: 1.1em;
}

.my-story__toc a:hover {
    text-decoration: underline;
}

.my-research__icon {
    margin-right: 0.5em;
    font-size: 1.5em;
    color: var(--clr-primary);
    transform: translateY(0.2em);
}

.rectangular-image-container {
    background-color: white;
    float: right;
    margin-left: 1em;
    margin-bottom: 1em;
    border-radius: 8px;
    overflow: hidden; /* This ensures the image doesn't overflow the rounded corners */
}

.rectangular-image-container img {
    display: block;
    max-width: 300px;
    height: auto;
    border-radius: 4px;
}
