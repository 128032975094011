.about {
  flex-direction: column;
  margin-top: 3em;
  text-align: center;
}

.about__name {
  color: var(--clr-primary);
}

.about__role {
  margin-top: 1.2em;
}

.about__desc {
  font-size: 1rem;
  max-width: 600px;
}

.about__desc {
  margin-top: 2.4em;
}

.about__contact {
  display: flex;
  justify-content: center;
  margin-top: 2.4em;
}

.about .link--icon {
  margin-right: 0.8em;
}

.about .btn--outline {
  margin-right: 1em;
}

@media (max-width: 600px) {
  .app .about {
    align-items: center;
    margin-top: 2em;
  }
  .about__contact {
    justify-content: center;
    margin-top: 2.4em;
  }
}

.about__desc-large {
  font-size: 1.2rem;
}

.about__desc-small {
  font-size: 0.9rem;
}
