.background-shapes-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.background-shapes {
    position: relative;
    width: 100%;
    min-height: 100vh;
}

.background-panel {
    position: absolute;
    top: 0;
    height: 100%;
    width: 20%;
    z-index: 0;
    transition: opacity 0.3s ease-in-out;
}

.left-panel {
    left: 0;
}

.right-panel {
    right: 0;
}

.shape {
    fill: none;
    stroke-width: 3;
    opacity: 0.45;
}

@media (max-width: 1000px) {
    .background-shapes-container {
        display: none;
    }
}