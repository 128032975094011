.profile__content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 2em;
  max-width: 1100px;
  margin: 0 auto;
}

.profile__image-container {
  width: 100%;
  max-width: 340px;
}

.profile__image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  max-width: 340px;
}

.profile__bio {
  flex: 1;
}

.profile__paragraph {
  margin-bottom: 1em;
}

.profile__bio-large {
  font-size: 1.1em;
}

.profile__bio-small {
  font-size: 0.9em;
}

.profile__read-more {
  display: flex;
  justify-content: center;
  margin-top: 1.5em;
  gap: 1em;
}

@media (max-width: 1400px) {
  .profile__content {
    flex-direction: column;
    align-items: center;
  }

  .profile__image-container {
    max-width: 300px;
    margin-bottom: 1em;
  }

  .profile__bio {
    text-align: center;
  }

  .profile__image {
    max-width: 300px;
  }
}

@media (max-width: 768px) {
  .profile__bio-large {
    font-size: 1em;
  }

  .profile__bio-small {
    font-size: 0.85em;
  }

  .profile__image-container {
    max-width: 250px;
  }

  .profile__image {
    max-width: 250px;
  }
}

@media (max-width: 480px) {
  .profile__content {
    gap: 1em;
  }

  .profile__image-container {
    max-width: 80vw;
  }

  .profile__bio-large {
    font-size: 0.95em;
  }

  .profile__bio-small {
    font-size: 0.8em;
  }

  .profile__image {
    max-width: 80vw;
  }
}
